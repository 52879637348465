import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import messagesAPI from '@/services/api/messagesAPI';

// Types
import type { Ref } from 'vue';
import type { Message } from '@/types/demandes';

export const useMessagesStore =  defineStore('messages', () => {

  const agentMessages: Ref<{ defaults: Message[], customs: Message[] }> = ref({
    defaults: [],
    customs: []
  });

  const defaultRegularisationMessage = computed(() => {
    return agentMessages.value.defaults.find(el => el.message_type === 3) ?? null;
  });
  const defaultAcceptMessage = computed(() => {
    return agentMessages.value.defaults.find(el => el.message_type === 4) ?? null;
  });
  const defaultRejectMessage = computed(() => {
    return agentMessages.value.defaults.find(el => el.message_type === 5) ?? null;
  });
  const customRegularisationMessage = computed(() => {
    return agentMessages.value.customs.find(el => el.message_type === 3) ?? null;
  });
  const customtAcceptMessage = computed(() => {
    return agentMessages.value.customs.find(el => el.message_type === 4) ?? null;
  });
  const customRejectMessage = computed(() => {
    return agentMessages.value.customs.find(el => el.message_type === 5) ?? null;
  });

  async function getAgentMessages() {
    const messages = await messagesAPI.getAgentMessages();
    agentMessages.value = messages;
  }

  async function createCustomMessage(data:object) {
    await messagesAPI.createAgentMessage(data);
    await getAgentMessages();
  }
  async function updateCustomMessage(id:number, data:object) {
    await messagesAPI.updateAgentMessage(id, data);
    await getAgentMessages();
  }

  return {
    agentMessages,
    defaultRegularisationMessage,
    defaultAcceptMessage,
    defaultRejectMessage,
    customRegularisationMessage,
    customtAcceptMessage,
    customRejectMessage,
    getAgentMessages,
    createCustomMessage,
    updateCustomMessage
  };

});
