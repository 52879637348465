import axios from 'axios';
import { readCookie } from '@/utils/cookies';

const API_URL = import.meta.env.VITE_API_URL;

const messagesAPI = {
  async getAgentMessages() {
    const token = readCookie('capToken');
    const url = `${API_URL}/messages_agent/`;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createAgentMessage(data:object) {
    const token = readCookie('capToken');
    const url = `${API_URL}/messages_agent/`;
    const response = await axios.post(
      url,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateAgentMessage(id:number, data:object) {
    const token = readCookie('capToken');
    const url = `${API_URL}/messages_agent/${id}/`;
    const response = await axios.put(
      url,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  }
};

export default messagesAPI;