// import type { DataControl } from '@/types/demandes';

export function controlsOnly(raw:any) {
  return Object.keys(raw)
    .filter(key => key.startsWith('c'))
    .reduce((obj:any, key:string) => {
      obj[key] = raw[key];
      return obj;
    }, {});
}

export function capitalizeFirstLetter(string:string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function downloadFile(file:{ name:string, url:Blob }) {
  const fileName =
    file.name && file.name.split('/').length && file.name.split('/').slice(-1)[0].split('.').length ?
      file.name.split('/').slice(-1)[0].split('.')[0] :
      'file';
  const href = URL.createObjectURL(file.url);
  const link = document.createElement('a');
  link.href = href;
  
  const extensionsTable:{[key: string]: string} = {
    'text/plain': 'txt',
    'application/x-zip-compressed': 'zip',
    'application/zip': 'zip',
  };
  
  link.setAttribute('download', `${fileName}.${extensionsTable[file.url.type] ? extensionsTable[file.url.type] : 'pdf'}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function formatDate(dateString:string, separator = '-') {
  const date = new Date(dateString).toLocaleDateString();
  const time = new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' });
  return `${date} ${separator} ${time}`;
}

export function compareDates(a: Date, b: Date): number {
  if (a < b) return -1;
  if (a > b) return +1;

  return 0; // dates are equal
}

export function toggleDocumentBodyScroll(bool:boolean) {
  if (bool) {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  } else {
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
  }
}

export function slugify(string:string) {
  return string
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/gi, '')
    .replace(/[^A-Z0-9]/gi, '-')
    .replace(/[^A-Z0-9]+$/gi, '')
    .replace(/^[^A-Z0-9]+/gi, '')
    .toLowerCase();
}