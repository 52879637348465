const DashboardView = () => import('../features/dashboard/views/DashboardView.vue');
const DAView = () => import('../features/DA/views/DAView.vue');
const SettingsView = () => import('../features/settings/views/SettingsView.vue');
const HistoryView = () => import('../features/history/views/HistoryView.vue');

export const protectedRoutes = [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: DashboardView,
		meta: { requiresAuth: true },
	},
	{
		path: '/da/:id',
		name: 'da',
		component: DAView,
		meta: { requiresAuth: true },
	},
	{
		path: '/settings',
		name: 'settings',
		component: SettingsView,
		meta: { requiresAuth: true },
	},
	{
		path: '/history',
		name: 'history',
		component: HistoryView,
		meta: { requiresAuth: true },
	}
];
