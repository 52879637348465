import axios, { AxiosError } from 'axios';
import { readCookie } from '@/utils/cookies';

const API_URL = import.meta.env.VITE_API_URL;

const sdifAPI = {
  async getSdifCenters() {
    const token = readCookie('capToken');
    const url = `${API_URL}/centres/`;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return [];
  },

  async getSdifAgents(id:number) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/centres/${id}/agents/`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch {
      return [];
    }
  },

  async getAgentsList(all = false) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/agent_sdifs/${all ? '?all=true' : ''}`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch {
      return [];
    }
  },
  async updateSdifAgent(id:number, data:object) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/agent_sdifs/${id}/`;
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        throw err.response?.data;
      }
    }
  },
  async createSdifAgent(data:object) {
    const token = readCookie('capToken');
    const url = `${API_URL}/agent_sdifs/`;
    const response = await axios.post(
      url,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async deleteSdifAgent(id:number) {
    const token = readCookie('capToken');
    const url = `${API_URL}/agent_sdifs/${id}/`;
    const response = await axios.delete(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async reinitAgentPassword(data:object) {
    const url = `${API_URL}/agent_sdifs/reinitialisation-mdp/`;
    const response = await axios.post(
      url,
      data,
    );
    return response;
  }
};

export default sdifAPI;