import axios from 'axios';
import { readCookie } from '@/utils/cookies';

import { ref } from 'vue';
import { defineStore } from 'pinia';

// Stores
import { useUserStore } from '@/stores/user';
import { useMessagesStore } from '@/stores/messages';
import { useDemandesStore } from '@/stores/demandes';

// Types
import type { Ref } from 'vue';
import type { Alert } from '@/types/index';

const API_URL = import.meta.env.VITE_API_URL;

export const useAppStore =  defineStore('app', () => {

  const userStore = useUserStore()
  const { getUserInfo } = userStore;
  const messagesStore = useMessagesStore();
  const { getAgentMessages } = messagesStore;
  const demandesStore = useDemandesStore();
  const { getAllDemandesList } = demandesStore;

  const isAppLoading = ref(false);

  const alerts: Ref<Alert[]> = ref([]);

  function setAppLoader(value: boolean) {
    isAppLoading.value = value;
  }

  async function getAlerts() {
    const token = readCookie('capToken');
    const url =`${API_URL}/alertes/`;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  }

  function resetAlerts() {
    alerts.value = [];
  }

  async function getAppData(onlyEssential = true) {
    try {
      setAppLoader(true);
      await getUserInfo();
      await getAgentMessages();
      const alertes = await getAlerts();
      if (alertes) {
        alerts.value = [...alertes];
      }
      if (!onlyEssential) await getAllDemandesList();
      setTimeout(() => {
        setAppLoader(false);
      }, 100);
    } catch (err) {
      console.error(err);
      setAppLoader(false);
    }
  }

  return {
    isAppLoading,
    alerts,
    setAppLoader,
    resetAlerts,
    getAppData
  };

});
