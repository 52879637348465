import jwt_decode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';

import { createRouter, createWebHashHistory } from 'vue-router';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

import { readCookie } from '@/utils/cookies';

// Stores
import { useAppStore } from '@/stores/app';

const routes = [...protectedRoutes, ...publicRoutes];
const router = createRouter({ history: createWebHashHistory(import.meta.env.BASE_URL), routes });

router.beforeEach(async (to, from) => {
  const appStore = useAppStore();
  const { getAppData } = appStore;

  const cookie = readCookie('capToken');
  
  if (to.meta.requiresAuth && !cookie) {
    // this route requires auth, check if logged in, if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  } else if (to.meta.requiresAuth && cookie) {
    // Check if cookie valid
    const decoded = jwt_decode<JwtPayload>(cookie);
    if ((decoded.exp && Date.now() >= decoded.exp * 1000) || !decoded.exp) {
      return {
        path: '/login',
        // save the location we were at to come back later
        query: { redirect: to.fullPath },
      }
    } else {
      const shouldFetchDemandes = to.name && ['dashboard', 'history'].includes(to.name.toString());
      await getAppData(!shouldFetchDemandes);
      if (from.query.redirect && from.query.redirect !== to.path) {
        return { path: from.query.redirect.toString() }
      }
    }
  }
});

export default router;
