import axios from 'axios';
import { readCookie } from '@/utils/cookies';

const API_URL = import.meta.env.VITE_API_URL;

const documentsAPI = {

  async getAllDocuments(id:number|string) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/documents/fichier/all/${id}`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch {
      return false;
    }
  },

  async getControlPointsList() {
    const token = readCookie('capToken');
    const url = import.meta.env.VITE_URL_CAP_CONTROL_POINTS;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        },
        responseType: 'blob'
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  }
};

export default documentsAPI;