const LandingView = () => import('../features/misc/views/LandingView.vue');
const LoginView = () => import('../features/login/views/LoginView.vue');
export const publicRoutes = [
	{
		path: '/',
		name: 'landing',
		component: LandingView,
		meta: { requiresAuth: false },
		beforeEnter: () => {
      return {
				path: '/dashboard',
			};
		}
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
		meta: { requiresAuth: false },
	},
];
