import axios from 'axios';
import { readCookie } from '@/utils/cookies';

import type { UserLoginForm } from '@/types/user';

const API_URL = import.meta.env.VITE_API_URL;

const userAPI = {

  async getUserToken(form: UserLoginForm) {
    try {
      const url =`${API_URL}/token/`;
      const response = await axios.get(url,
        {
          auth: form
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async getUserInfo() {
    try {
      const token = readCookie('capToken');
      const url =`${API_URL}/user_info/`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },
};

export default userAPI;