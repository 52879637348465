import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import sdifAPI from '@/services/api/sdifAPI';

// Types
import type { Ref } from 'vue';
import type { Agent, Center } from '@/types/user';

export const useSdifStore =  defineStore('sdif', () => {

  const sdifCenters: Ref<Center[]> = ref([])

  const sdifAgentsList: Ref<Agent[]> = ref([]);
  const fullAgentsList: Ref<Agent[]> = ref([]);

  const verificators: Ref<Agent[]> = computed(() => {
    return sdifAgentsList.value ?
      sdifAgentsList.value.filter(agt => [1, 2].includes(agt.qualite)) :
      [];
  });
  const inspectors: Ref<Agent[]> = computed(() => {
    return sdifAgentsList.value ?
      sdifAgentsList.value.filter(agt => agt.qualite === 2) :
      [];
  });

  async function getSdifCentersList() {
    const centers = await sdifAPI.getSdifCenters();
    sdifCenters.value = centers;
  }

  async function getSdifAgentsList(sdifID:number) {
    const agents = await sdifAPI.getSdifAgents(sdifID);
    sdifAgentsList.value = agents;
  }

  async function getFullAgentsList(all = false) {
    const agents = await sdifAPI.getAgentsList(all);
    fullAgentsList.value = agents;
  }

  async function createSdifAgent(data:object) {
    await sdifAPI.createSdifAgent(data);
    await getFullAgentsList();
  }
  async function updateSdifAgent(id:number, data:object) {
    await sdifAPI.updateSdifAgent(id, data);
    await getFullAgentsList();
  }
  async function deleteSdifAgent(id:number) {
    await sdifAPI.deleteSdifAgent(id);
    await getFullAgentsList();
  }

  return {
    sdifCenters,
    sdifAgentsList,
    fullAgentsList,
    verificators,
    inspectors,
    getSdifCentersList,
    getSdifAgentsList,
    getFullAgentsList,
    createSdifAgent,
    updateSdifAgent,
    deleteSdifAgent
  };
});
