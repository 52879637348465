import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import router from '@/router';

import userAPI from '@/services/api/userAPI';
import sdifAPI from '@/services/api/sdifAPI';
import { createCookie } from '@/utils/cookies';

// Types
import type { Ref } from 'vue';
import type { UserLoginForm, User, UserCenter } from '@/types/user';

// Stores
import { useSdifStore } from '@/stores/sdif';

export const useUserStore =  defineStore('user', () => {
  const sdifStore = useSdifStore();
  const {
    getSdifCentersList,
    getSdifAgentsList
  } = sdifStore;

  const loginForm: Ref<UserLoginForm> = ref({
    username: '',
    password: ''
  });

  const userToken: Ref<string> = ref('');
  const isLoggedIn = computed(() => {
    return userToken.value !== '';
  });

  const loginError: Ref<boolean> = ref(false);


  const userInfo: Ref<User | null> = ref(null);

  const userSDIF: Ref<UserCenter | null> = ref(null);

  const isUserGF3A = computed(() => {
    return userInfo.value?.qualite === 3;
  })

  function setLoginForm(form: UserLoginForm) {
    if (
      form.username.toLowerCase() !== loginForm.value.username ||
      form.password !== loginForm.value.password
    ) {
      loginError.value = false;
    }
    loginForm.value.username = form.username.toLowerCase();
    loginForm.value.password = form.password;
  }

  async function getUserToken() {
    try {
      // Check if username is an email, if not add dgfip mail domain
      if (loginForm.value.username.indexOf('@') === -1) {
        loginForm.value.username = loginForm.value.username.concat('@dgfip.finances.gouv.fr');
      }
      // Block login if maintenance mode
      const maintenanceMode = import.meta.env.VITE_MAINTENANCE === 'true'; 
      if (maintenanceMode && loginForm.value.username.includes('@dgfip.finances.gouv.fr')) {
        alert('L`application est en cours de maintenance. Veuillez réessayez plus tard.');
        return;
      } else {
        const response = await userAPI.getUserToken(loginForm.value);
        if (response && response.token) {
          userToken.value = `Bearer ${response.token}`;
          createCookie('capToken', `Bearer ${response.token}`);
        } else {
          userToken.value = '';
        }
        await getUserInfo();
      }
    } catch (err) {
      loginError.value = true;
    }
  }

  async function getUserInfo() {
    const infos = await userAPI.getUserInfo();
    userInfo.value = infos.user;
    if (!isUserGF3A.value && userInfo.value?.centres && userInfo.value?.centres.length) {
      const activeCenters = userInfo.value?.centres.filter(el => el.acces_actif === true);
      if (activeCenters && activeCenters.length) {
        const lastConnectedCenter = activeCenters.find(el => el.last_connected === true);
        userSDIF.value = lastConnectedCenter ?? activeCenters[0]
      } else {
        userSDIF.value = null;
      }
    }
    await getSdifCentersList();
    if (userSDIF.value && userSDIF.value.centre && userSDIF.value.centre.id && !isUserGF3A.value) {
      await getSdifAgentsList(userSDIF.value.centre.id);
    }
  }

  async function updateUserActiveCenter(centreId:number) {
    if (userInfo.value?.id) {
      const data = {
        last_connected: centreId,
      };
      await sdifAPI.updateSdifAgent(userInfo.value?.id, data);
      await getUserInfo();
    }
  }

  async function updateUserInfo(data:object) {
    if (userInfo.value?.id) {
      await sdifAPI.updateSdifAgent(userInfo.value?.id, data);
      await getUserInfo();
    }
  }

  async function accessCAP(centre?:UserCenter) {
    userSDIF.value = null;
    if (centre) {
      userSDIF.value = centre;
      await getSdifAgentsList(userSDIF.value.centre.id);
    }
    if (router.currentRoute.value.query.redirect) {
      router.push({
        path: router.currentRoute.value.query.redirect.toString()
      });
    } else {
      router.push({
        name: 'dashboard'
      });
    }
  }

  function resetUserInfo() {
    userInfo.value = null;
    userSDIF.value = null;
    loginForm.value = {
      username: '',
      password: ''
    };
  }

  return {
    loginForm,
    userToken,
    isLoggedIn,
    loginError,
    userInfo,
    userSDIF,
    isUserGF3A,
    setLoginForm,
    getUserToken,
    getUserInfo,
    updateUserActiveCenter,
    updateUserInfo,
    accessCAP,
    resetUserInfo
  };

});
