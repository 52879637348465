import axios from 'axios';
import { readCookie } from '@/utils/cookies';

const DEV_AUTH = import.meta.env.NODE_ENV === 'development' ? true : false;
if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    const re = new RegExp(name + '=([^;]+)');
    const value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const API_URL = import.meta.env.VITE_API_URL;

const demandesAPI = {

  async getInProgressDemandes() {
    const token = readCookie('capToken');
    const url =`${API_URL}/demandes/`;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getArchivedDemandes(
    controller:AbortController,
    direction:string,
    field:string,
    offset = 0,
    myDAsSort = false,
    searchQuery  = ''
  ) {
    const token = readCookie('capToken');
    let url =`${API_URL}/demandes/?statut=archive&offset=${offset}&${['agent_verificateur', 'agent_referent'].includes(field) ? 'order_by_agent' : 'order_by'}=${direction}${field}`;
    if (myDAsSort) {
      url = url.concat('', '&mes_da=true');
    }
    if (searchQuery) {
      url = url.concat('', `&search=${searchQuery}`)
    }
    const response = await axios.get(
      url,
      {
        signal: controller.signal,
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async exportArchivedDemandes(direction:string, field:string, query = '') {
    const token = readCookie('capToken');
    const url =`${API_URL}/demandes/export_csv/?statut=archive&order_by=${direction}${field}&search=${query}`;
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: token
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDemande(id:number|string) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/demandes/${id}/`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async updateDemande(id:number|string, data:object) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/demandes/${id}/`;
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async getDecision(id:number) {
    const token = readCookie('capToken');
    const url = `${API_URL}/decision/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            Authorization: token
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
  },

  async sendRegularisation(data:object) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/regularisation/`;
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.status === 201) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async updateRegularisation(id:number, data:object) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/regularisation/${id}/`;
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: token,
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async sendDecision(data:object) {
    try {
      const token = readCookie('capToken');
      const url = `${API_URL}/decision/`;
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.status === 201) {
        return response.data;
      }
      return false;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.error) {
        throw err.response.data.error;
      }
      throw 'Une erreur est survenue';
    }
  },
};

export default demandesAPI;