import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router';
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import Dropdown from "primevue/dropdown";
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import PDFObjectPlugin from 'pdfobject-vue';
import "primevue/resources/themes/lara-light-indigo/theme.css";

const pinia = createPinia();
const app = createApp(defineAsyncComponent(() => import('@/App.vue')));

Sentry.init({
  app,
  dsn: "https://5956e2167a016b30370d528c093a1c99@sentry.neogeo.fr/7",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/neogeo.sentry\.fr\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(pinia);
app.use(PrimeVue);
app.use(ToastService);
app.use(PDFObjectPlugin);
app.component('DropDown', Dropdown);
app.directive('tooltip', Tooltip);

app.mount('#app');
