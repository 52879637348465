import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

import demandesAPI from '@/services/api/demandesAPI';
import documentsAPI from '@/services/api/documentsAPI';
import { controlsOnly, formatDate } from '@/utils';

// Types
import type { Ref } from 'vue';
import type { Demande, Demandes } from '@/types/demandes';

export const useDemandesStore =  defineStore('demandes', () => {

  /************************ STATE *******************************************/
  const archivedDemandesCount = ref(0);
  const inProgressDemandesList: Ref<Demandes[]> = ref([]);
  const archivedDemandesList: Ref<Demandes[]> = ref([]);
  const currentDemande: Ref<Demande | null> = ref(null);

  // Archives ordering
  const archivesOrderingDirection = ref('-');
  const archivesOrderingField = ref('date_fin');
  // Archives sorting
  const archivesMyDASort = ref(false);
  // Archives search
  const archivesSearchQuery = ref('');
  const abortController:Ref<AbortController | null> = ref(null);

  /************************ COMPUTED ****************************************/
  // Demandes list
  // const inProgressDemandesList = computed(() => {
  //   return demandesList.value.filter((demande) => {
  //     return !demande.statut_code_sdif || ![205, 207, 210].includes(demande.statut_code_sdif.identifiant);
  //   })
  // });
  // const archivedDemandesList = computed(() => {
  //   return demandesList.value.filter((demande) => {
  //     return demande.statut_code_sdif && [205, 207, 210].includes(demande.statut_code_sdif.identifiant);
  //   })
  // });
  const inProgressDemandesCount = computed(() => {
    return inProgressDemandesList.value.length;
  });
  const attributedInProgressDemandesCount = computed(() => {
    return inProgressDemandesList.value.filter((demande) => {
      return demande.agents.length > 0;
    }).length;
  });
  const attributedArchivedDemandesCount = computed(() => {
    return archivedDemandesList.value.filter((demande) => {
      return demande.agents.length > 0;
    }).length;
  });
  // Demande
  const currentDemandeGEStatus = computed(() => {
    return currentDemande.value && currentDemande.value.statut_code_ge ?
      currentDemande.value.statut_code_ge.slug : null;
  });
  const currentDemandeSDIFStatus = computed(() => {
    return currentDemande.value && currentDemande.value.statut_code_sdif ?
      currentDemande.value.statut_code_sdif.slug : null;
  });
  const currentDemandeFirstHandling = computed(() => {
    if (currentDemande.value && currentDemande.value.historique && currentDemande.value.historique.length) {
      const handlings = currentDemande.value.historique
        .filter(el => { return el.statut_code === 202 })
        .sort((a, b) => { return +new Date(a.statut_horodate) - +new Date(b.statut_horodate)});
      const firstHandling = handlings[0];
      if (firstHandling) {
        return firstHandling;
      }
    }
    return null;
  });
  const currentDemandeControls: any = computed(() => {
    if (currentDemande.value && currentDemande.value.controls) {
      const controls = controlsOnly(currentDemande.value.controls.data_api);
      const sortedControls = Object.entries(controls)
        .sort(([,a]:any,[,b]:any) => {
          if (a.ID && b.ID) {
            return a.ID.localeCompare(b.ID, undefined, { numeric: true });
          }
          return 0;
        })
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        return sortedControls;
    } else {
      return null;
    }
  });
  const currentDAControlsFailed = computed(() => 
    currentDemandeControls.value ?
      Object.keys(currentDemandeControls.value).reduce((obj:any, key) => {
        if (currentDemandeControls.value[key].status === false) {
          obj[key] = currentDemandeControls.value[key];
        }
        return obj;
      }, {}) :
      null
  );
  const currentDAVerificators = computed(() => {
    return currentDemande.value && currentDemande.value.agents.length ?
      currentDemande.value.agents.find(el => el.id_role === 1)?.id_sdif_agent : null;
  });
  const currentDAInspectors = computed(() => {
    return currentDemande.value && currentDemande.value.agents.length ?
      currentDemande.value.agents.find(el => el.id_role === 2)?.id_sdif_agent : null;
  });

  const isCurrentDemandeEditable = computed(() => {
    return currentDemande.value && ![205, 207, 209, 210].includes(currentDemande.value.statut_code_sdif.identifiant);
  });

  const getGEHorodate = (id:number) => {
    const demande = inProgressDemandesList.value.find(el => el.id === id);
    if (demande && demande.statut_horodate_ge) {
      return formatDate(demande.statut_horodate_ge);
    }
    return '';
  };

  /******************************* DATA FETCH *********************************/

  function setArchivesSearchQuery(query) {
    archivesSearchQuery.value = query;
  }
  function setArchivesOrdering(direction:string, field:string) {
    archivesOrderingDirection.value = direction;
    archivesOrderingField.value = field;
  }

  function setArchivesMyDASort(value:boolean) {
    archivesMyDASort.value = value;
  }

  async function getInProgressDemandesList() {
    const demandes = await demandesAPI.getInProgressDemandes();
    if (demandes) {
      inProgressDemandesList.value = demandes.results;
    }
    return;
  }
  async function getArchivedDemandesList(offset = 0) {
    if (abortController.value) {
      abortController.value.abort();
    }
    abortController.value = new AbortController();
    const demandes = await demandesAPI.getArchivedDemandes(
      abortController.value,
      archivesOrderingDirection.value,
      archivesOrderingField.value,
      offset,
      archivesMyDASort.value,
      archivesSearchQuery.value
    );
    abortController.value = null;
    if (demandes) {
      archivedDemandesList.value = demandes.results;
      archivedDemandesCount.value = demandes.count;
    }
    return;
  }

  async function exportArchivedDemandesList() {
    return await demandesAPI.exportArchivedDemandes(archivesOrderingDirection.value, archivesOrderingField.value, archivesSearchQuery.value);
  }

  async function getAllDemandesList() {
    await getInProgressDemandesList();
    await getArchivedDemandesList();
  }

  async function getCurrentDemande(id:number|string) {
    const demande = await demandesAPI.getDemande(id);
    currentDemande.value = demande;    
  }

  async function getAllDemandeDocuments(id:number|string) {
    const response = await documentsAPI.getAllDocuments(id);
    if (currentDemande.value) {
      await getCurrentDemande(currentDemande.value.id);
    }
    return response;
  }

  async function getDecision(id: number) {
    const decision = await demandesAPI.getDecision(id);
    return decision;
  }

  /******************************* DATA SEND *********************************/
  async function changeDemandeEmergencyStatus(id: number, emergencyStatus: boolean) {
    await demandesAPI.updateDemande(id, {
      sdif_urgence: emergencyStatus
    });
  }

  async function sendDemandeRegularisation(data:object) {
    await demandesAPI.sendRegularisation(data);
    await getInProgressDemandesList();
    if (currentDemande.value) {
      await getCurrentDemande(currentDemande.value.id);
    }
  }

  async function updateDemandeRegularisation(id:number, data:object) {
    await demandesAPI.updateRegularisation(id, data);
    if (currentDemande.value) {
      await getCurrentDemande(currentDemande.value.id);
    }
  }

  async function sendDemandeDecision(data:object) {
    await demandesAPI.sendDecision(data);
    if (currentDemande.value) {
      await getCurrentDemande(currentDemande.value.id);
    }
    await getAllDemandesList();
  }

  async function archiveDemande(id:number) {
    await demandesAPI.updateDemande(id, {
      action: 'archiver'
    });
    await getAllDemandesList();
  }

  return {
    archivedDemandesCount,
    inProgressDemandesCount,
    attributedInProgressDemandesCount,
    attributedArchivedDemandesCount,
    inProgressDemandesList,
    archivedDemandesList,
    archivesMyDASort,
    currentDemande,
    currentDemandeGEStatus,
    currentDemandeSDIFStatus,
    currentDemandeFirstHandling,
    currentDemandeControls,
    currentDAControlsFailed,
    currentDAVerificators,
    currentDAInspectors,
    isCurrentDemandeEditable,
    getGEHorodate,
    setArchivesSearchQuery,
    setArchivesOrdering,
    setArchivesMyDASort,
    getInProgressDemandesList,
    getArchivedDemandesList,
    exportArchivedDemandesList,
    getAllDemandesList,
    getCurrentDemande,
    changeDemandeEmergencyStatus,
    getAllDemandeDocuments,
    getDecision,
    sendDemandeRegularisation,
    updateDemandeRegularisation,
    sendDemandeDecision,
    archiveDemande
  };

});
